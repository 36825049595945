$white: #ffffff;
.lead_content {
    position: fixed;
    left: 416px;
    right: 382px;
    top: 60px;
    bottom: 0;
    padding: 15px 0;
    @include media-below(xl) {
        left: 310px;
        right: 328px;
    }
    &.settings_view {
        left: 310px;
    }
}
.lead_tabs {
    border-bottom: 1px solid #e8e8e8;
    .MuiTabs-flexContainer {
        justify-content: space-between;
        .MuiTab-textColorPrimary {
            @include media-below(xl) {
                min-width: auto;
            }
        }
        .MuiTab-textColorPrimary.Mui-selected{
            color : #005E9D !important;
        }
    }
}
.tab_card {
    position: absolute;
    right: 0;
    left: 0;
    bottom: 10px;
    top: 98px;
}
.v_scroll {
    height: calc(100% - 100px);
    overflow-y: auto;
    overflow-x: hidden;
}
.v_scroll .tab_card .MuiBox-root {
    height: calc(100vh - 260px);
}
.box_portlet {
    border: 1px solid #ebedf2;
    &.border-0 {
        border: 0;
        .box_header {
            border: 1px solid #ebedf2;
            border-bottom: 0;
        }
    }
    .box_header {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: stretch;
        -ms-flex-align: stretch;
        align-items: stretch;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        border-bottom: 1px solid #ebedf2;
        min-height: 45px;
        background-color: #fafafa;
        padding: 0 16px;
        .box_label {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            -ms-flex-line-pack: flex-first;
            align-content: flex-first;
            .box_title {
                margin: 0;
                padding: 0;
                font: var(--font-body-2);
            }
        }
        .box_tools {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            -ms-flex-line-pack: end;
            align-content: flex-end;
        }
    }
    .box_body {
        .hover_hide {
            display: block;
            transition: all 0.3s ease-in-out;
            -moz-transition: all 0.3s ease-in-out;
            -webkit-transition: all 0.3s ease-in-out;
        }
        &:hover .hover_hide {
            display: none;
        }
        .Toggle_hover_hide {
            display: none;
            transition: all 0.3s ease-in-out;
            -moz-transition: all 0.3s ease-in-out;
            -webkit-transition: all 0.3s ease-in-out;
        }
        &:hover .Toggle_hover_hide {
            display: block;
        }
        padding: 16px;
        .lead_pl {
            position: relative;
            padding-left: 22px;
            .icon_left {
                position: absolute;
                left: 0;
                font-size: 1.3rem;
                color: var(--primary);
                top: 0;
            }
            .font-size-14 {
                font-size: 14px;
            }
            .MuiFab-secondary {
                background-color: var(--secondary);
                box-shadow: none;
                width: 32px;
                height: 32px;
                min-height: 32px;
                box-shadow: none;
                &:hover {
                    background-color: rgb(178, 110, 46);
                }
            }
            .bg-grey {
                background-color: #e0e0e0;
                box-shadow: none;
                width: 32px;
                color: var(--text-body);
                height: 32px;
                min-height: 32px;
                box-shadow: none;
                &:hover {
                    background-color: var(--primary);
                    color: #fff;
                }
            }
            .bg-success {
                background-color: #08ad6c;
                box-shadow: none;
                width: 32px;
                color: #fff;
                height: 32px;
                min-height: 32px;
                box-shadow: none;
                &:hover {
                    background-color: #048653;
                }
            }
            .bg-grey_sidebar {
                text-decoration: none;
                background-color: #d5d5d5;
                width: 40px;
                height: 40px;
            }
            .activity_icon_normal {
                display: inherit;
                font-size: 20px;
                color: #7467ef;
            }
            .bg-success-task_sidebar {
                background-color: #08ad6c;
                width: 40px;
                color: #fff;
                height: 40px;
                &:hover {
                    background-color: #048653;
                    .hover_icon {
                        display: block;
                    }

                    .activity_icon {
                        display: none;
                    }
                }

                .activity_icon {
                    display: inherit;
                    font-size: 20px;
                    color: #fff !important;
                }

                .hover_icon {
                    display: none;
                    color: #fff !important;
                }
            }
            .bg-grey_sidebar {
                text-decoration: none;
                background-color: #d5d5d5;
                width: 40px;
                height: 40px;
            }
            .activity_icon_normal {
                display: inherit;
                font-size: 20px;
                color: #7467ef;
            }
            .bg-success-task_sidebar {
                background-color: #08ad6c;
                width: 40px;
                color: #fff;
                height: 40px;
                &:hover {
                    background-color: #048653;
                    .hover_icon {
                        display: block;
                    }

                    .activity_icon {
                        display: none;
                    }
                }

                .activity_icon {
                    display: inherit;
                    font-size: 20px;
                    color: #fff !important;
                }

                .hover_icon {
                    display: none;
                    color: #fff !important;
                }
            }
        }
    }
}

.label-size-14 {
    label {
        font: var(--font-label);
    }
}
.vert-middle {
    vertical-align: middle;
    line-height: 20px;
}
.border_bx {
    padding: 10px 30px 10px 10px;
    border: 2px solid #ebedf2;
    margin-top: 5px;
    position: relative;
    font-size: 14px;
    .hover_show {
        position: absolute;
        opacity: 0;
        right: 5px;
        top: 5px;
    }
    &:hover .hover_show {
        opacity: 1;
    }
}
.assign_list {
    p {
        color: var(--text-body);
        font-size: 1rem;
    }
}
.ml-auto {
    margin-left: auto !important;
}
.lead_right {
    position: fixed;
    right: 16px;
    width: 350px;
    top: 60px;
    bottom: 0;
    padding: 15px 0;
    overflow-y: auto;
    @include media-below(xl) {
        width: 295px;
    }
    .lead_pl {
        position: relative;
        .hover_show {
            position: absolute;
            opacity: 0;
            right: -5px;
            top: 0;
        }
        &:hover .hover_show {
            opacity: 1;
        }
    }
    .box_portlet {
        &:hover {
            background-color: rgba(233, 231, 228, 0.4);
        }
        .MuiAvatar-colorDefault {
            background-color: #e0e0e0;
        }
        border: none;
        .box_header {
            min-height: 34px;
            padding: 0 10px;
            .box_title {
                font: var(--font-panel-heading);
            }
        }
        .box_body {
            .opp_name {
                max-width: 110px;
            }
            padding: 0px;
            // padding: 10px 16px;
            .opportunity-progress {
                height: 6px;
                margin-top: 5px;
                &.MuiLinearProgress-colorPrimary {
                    background-color: rgba(116, 103, 239, 0.4);
                    .MuiLinearProgress-barColorPrimary {
                        background-color: var(--primary);
                    }
                }
                &.secondary {
                    background-color: rgba(255, 158, 67, 0.4);
                    .MuiLinearProgress-barColorPrimary {
                        background-color: var(--secondary);
                    }
                }
                &.yellow {
                    background-color: rgba(242, 191, 88, 0.4);
                    .MuiLinearProgress-barColorPrimary {
                        background-color: var(--yellow);
                    }
                }
                &.green {
                    background-color: rgba(8, 173, 108, 0.4);
                    .MuiLinearProgress-barColorPrimary {
                        background-color: var(--green);
                    }
                }
                &.red {
                    background-color: rgba(255, 0, 0, 0.4);
                    .MuiLinearProgress-barColorPrimary {
                        background-color: rgba(255, 0, 0, 1);
                    }
                }
                &.blue {
                    background-color: rgba(0, 0, 255, 0.4);
                    .MuiLinearProgress-barColorPrimary {
                        background-color: rgba(0, 0, 255, 1);
                    }
                }
                &.black {
                    background-color: rgba(0, 0, 0, 0.4);
                    .MuiLinearProgress-barColorPrimary {
                        background-color: rgba(0, 0, 0, 1);
                    }
                }
                &.orange {
                    background-color: rgba(255, 165, 0, 0.4);
                    .MuiLinearProgress-barColorPrimary {
                        background-color: rgba(255, 165, 0, 1);
                    }
                }
                &.purple {
                    background-color: rgba(128, 0, 128, 0.4);
                    .MuiLinearProgress-barColorPrimary {
                        background-color: rgba(128, 0, 128, 1);
                    }
                }
                &.gray {
                    background-color: rgba(128, 128, 128, 0.4);
                    .MuiLinearProgress-barColorPrimary {
                        background-color: rgba(128, 128, 128, 1);
                    }
                }
            }
        }
        .dorpdown_menu {
            li {
                padding-left: 0;
                .dorpdown_menu_list {
                    p {
                        font-size: 13px;
                    }
                }
            }
        }
    }
}

.box_action {
    position: relative;
    .hover_show {
        visibility: hidden;
        opacity: 0;
        position: absolute;
        right: 3px;
        bottom: 3px;
        transition: all 0.3s ease-in-out;
        -moz-transition: all 0.3s ease-in-out;
        -webkit-transition: all 0.3s ease-in-out;
        background-color: #fff;
    }
    &:hover .hover_show {
        visibility: visible;
        opacity: 1;
    }
    .bg-body {
        background-color: var(--text-body);
    }
}
button.btn_Primary {
    color: var(--bg-paper);
    background-color: var(--primary);
    box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.06), 0px 8px 10px 1px rgba(0, 0, 0, 0.042),
        0px 3px 14px 2px rgba(0, 0, 0, 0.036);
    &:hover {
        background-color: #0081D8;
        color: var(--bg-paper);
    }
}
button.btn-primary {
    background-color: #fff;
    color: var(--text-body);
    width: 34px;
    height: 34px;
    min-width: 34px;
    min-height: 34px;
    margin: 0 10px 3px;
    border: 1px solid #c9c9c9;
    box-shadow: none;
    @include media-below(xl) {
        margin: 0 5px 3px;
    }
    .material-icons {
        font-size: 1.3rem;
        color: var(--primary);
    }
    &:hover {
        background-color: #0081D8;
    }
}
div.MuiPickersToolbar-toolbar {
    background-color: var(--bg-paper);
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    &.MuiPickersTimePickerToolbar-toolbarAmpmLeftPadding {
        padding-left: 24px;
        padding-right: 24px;
        justify-content: left;
    }
}
.sidenav {
    top: 60px;
    bottom: 0;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    position: fixed;
    overflow: hidden;
    z-index: 99;
    left: 0;
    box-shadow: var(--elevation-z8);
    width: 400px;
    background-color: var(--bg-paper);
    @include media-below(xl) {
        width: 295px;
    }
    &.collepse_menu {
        width: 295px;
    }
    .sidenav__user {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 1.5rem 1rem 1rem;
        .username-photo {
            display: flex;
            flex-direction: column;
            align-items: center;
            img {
                height: 4rem;
                width: 4rem;
                border-radius: 50%;
                margin-bottom: 8px;
                max-width: 100%;
            }
            .username {
                font-size: 16px;
                max-width: 250px;
                color: var(--text-body) !important;
                font-weight: 500;
                margin-right: -26px;
                .filled_star {
                    .material-icons {
                        color: #f2bf58;
                        font-size: 16px;
                    }
                }
            }
            .user_designation {
                color: var(--text-body) !important;
                font-weight: 300;
                max-width: 195px;
                white-space: nowrap;
                min-height: 15px;
                text-overflow: ellipsis;
                text-transform: capitalize;
                overflow: hidden !important;
            }
        }
    }
    .input_bx {
        > div {
            &:before {
                border-color: transparent;
            }
        }
    }
    .MuiInputBase-input {
        font: var(--font-input);
    }
    .v_scroll {
        height: calc(100vh - 340px);
        &.no_button {
            height: calc(100vh - 310px);
        }
    }
}
.cursor_poiter {
    cursor: pointer;
}
.button_size {
    width: 30px;
    height: 30px;
}
.lead_pl {
    .hover_show {
        visibility: hidden;
        opacity: 0;
        transition: all 0.3s ease-in-out;
        -moz-transition: all 0.3s ease-in-out;
        -webkit-transition: all 0.3s ease-in-out;
    }
    &:hover .hover_show {
        visibility: visible;
        opacity: 1;
    }
}
.edit_bx {
    font-weight: 500;
    color: var(--text-body);
    font-size: 1rem;
    margin-left: 3px;
    border: 1px solid transparent;
    padding: 6px;
    position: relative;
    min-height: 38px;
    border-radius: 4px;
    .edit_hover_show {
        position: absolute;
        opacity: 0;
        right: 5px;
        top: 2px;
    }
    &:hover .edit_hover_show {
        opacity: 1;
    }
    &:hover {
        border-color: #ebedf2;
        background-color: #fafafa;
        cursor: text;
    }
}
.dialog_popup {
    .dialog_header {
        padding: 10px 16px;
        border-bottom: 1px solid #ebedf2;
    }
    .dialog_body {
        padding: 10px 16px;
        .quill {
            -ms-flex-direction: column-reverse;
            flex-direction: column-reverse;
            display: -ms-flexbox;
            display: flex;
            .ql-container.ql-snow {
                border-top: 1px solid #ccc;
                border-bottom-color: transparent;
                position: relative;
                .ql-blank {
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                }
            }
            .ql-toolbar.ql-snow {
                .ql-picker-options {
                    top: auto;
                    bottom: 100%;
                }
            }
        }
    }
    .dialog_footer {
        padding: 10px 16px;
    }
}
.aside_left {
    position: fixed;
    left: 16px;
    width: 260px;
    bottom: 0;
    top: 80px;
    .filter_menu {
        ul {
            list-style: none;
            margin: 10px 0;
            padding: 0;
            li {
                a {
                    color: var(--text-body);
                    padding: 8px 10px 8px 30px;
                    display: block;
                    &:hover {
                        background-color: #fafafa;
                        color: var(--primary);
                    }
                }
            }
        }
    }
    .filter_menu_custom {
        ul {
            list-style: none;
            margin: 10px 0;
            padding: 0;
            li {
                div {
                    color: var(--text-body);
                    padding: 2px 10px 8px 30px;
                    display: inline;
                    cursor: pointer;
                    width: 80%;
                    &:hover {
                        background-color: #fafafa;
                        color: var(--primary);
                    }
                }
            }
        }
    }
    .fixed_height {
        bottom: 16px;
        //position: absolute;
        position: absolute;
        //top: 363px;
        top: 253px;
        left: 0;
        right: 0;
        padding-bottom: 70px !important;
        &.LeadList_collapse {
            top: 64px;
        }
        .v_scroll {
            height: calc(100% - 35px);
        }
    }
}
.text_link {
    color: #2b41b2;
    font-size: 12px;
    font-weight: 500;
}
.fixed-bottom {
    position: absolute;
    bottom: 0;
    border-top: 1px solid #ebedf2;
    left: 0;
    right: 0;
    padding: 10px 16px 0;
}
.fixed_layout {
    position: fixed;
    left: 291px;
    top: 80px;
    bottom: 16px;
    right: 16px;
    padding: 16px 0;
    .circular-image-small {
        vertical-align: middle;
        margin-right: 15px;
    }
    .text_ellipsis {
        max-width: 175px;
        text-align: left;
        a {
            &:hover {
                text-decoration: underline;
                color: var(--text-body);
            }
        }
    }
}
.leads_list_table {
    max-height: 100%;
    > div {
        height: 100%;
    }
    .MuiInputBase-adornedStart {
        height: 40px;
    }
    .list_table_header {
        & + div {
            max-height: calc(100vh - 225px);
            border-top: 1px solid #ebedf2;
        }
    }
    thead {
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
        position: -webkit-sticky;
        position: sticky;
        z-index: 400;
        top: 0;
        background-color: #fff;
        th {
            font-size: 13px;
            font-weight: 500;
            text-align: left;
            padding-left: 10px;
            padding-right: 10px;
            position: -webkit-sticky;
            position: sticky;
            z-index: 400;
            top: 0;
            background-color: #fff;
            box-shadow: 0 1px 0px rgba(0, 0, 0, 0.1);
            span {
                color: #696e6f;
                overflow: inherit;
                text-overflow: inherit;
            }
            &:nth-child(1),
            &:nth-child(2) {
                z-index: 402;
            }
        }
        tr {
            &:first-child {
                th {
                    padding-top: 5px;
                    padding-bottom: 5px;
                }
            }
        }
    }
    tbody {
        tr {
            td {
                padding: 8px 10px;
                text-align: left;
            }
            .MuiCheckbox-colorSecondary {
                opacity: 0;
            }
            .text-secondary,
            .user_info {
                opacity: 0;
                width: 0;
            }
            .text_ellipsis {
                min-width: 120px;
            }
            .icon_visible {
                opacity: 1;
                width: auto;
            }
            .hover_icon_task{
                display: none;
            }
            .activity_icon_task{
                display: block;
            }
            &:hover {
                .hover_icon_task{
                    display: block;
                }
                .activity_icon_task{
                    display: none;
                }
                td {
                    background-color: #f5f5f5;
                }
                .MuiCheckbox-colorSecondary {
                    opacity: 1;
                }
                .text-secondary,
                .user_info {
                    opacity: 1;
                    width: auto;
                }
                .text_ellipsis {
                    width: 120px;
                }
            }
            .Mui-checked {
                opacity: 1;
            }
        }
        .text_link{
            font-size: 0.875rem;
        }
    }
    .MuiInputBase-adornedStart {
        &.Mui-focused {
            .MuiInputBase-inputAdornedStart {
                width: 200px;
            }
        }
    }
    .avatar_circle {
        display: inline-block;
        height: 36px;
        width: 36px;
        vertical-align: middle;
        margin-right: 15px;
        text-align: center;
        line-height: 36px;
        color: $white;
        &.color_a {
            background-color: rgba(171, 12, 255, 0.1);
            color: rgba(171, 12, 255, 1);
        }
        &.color_b {
            background-color: rgba(50, 131, 255, 0.1);
            color: rgba(50, 131, 255, 1);
        }
        &.color_c {
            background-color: rgba(133, 102, 12, 0.1);
            color: rgba(133, 102, 12, 1);
        }
        &.color_d {
            background-color: rgba(86, 86, 86, 0.1);
            color: rgba(86, 86, 86, 1);
        }
        &.color_e {
            background-color: rgba(120, 42, 183, 0.1);
            color: rgba(120, 42, 183, 1);
        }
        &.color_f {
            background-color: rgba(28, 131, 86, 0.1);
            color: rgba(28, 131, 86, 1);
        }
        &.color_g {
            background-color: rgba(23, 255, 50, 0.1);
            color: rgba(23, 255, 50, 1);
        }
        &.color_h {
            background-color: rgba(50, 71, 102, 0.1);
            color: rgba(50, 71, 102, 1);
        }
        &.color_i {
            background-color: rgba(128, 128, 128, 0.1);
            color: rgba(128, 128, 128, 1);
        }
        &.color_j {
            background-color: rgba(28, 191, 78, 0.1);
            color: rgba(28, 191, 78, 1);
        }
        &.color_k {
            background-color: rgba(197, 68, 28, 0.1);
            color: rgba(197, 68, 28, 1);
        }
        &.color_l {
            background-color: rgba(79, 20, 75, 0.1);
            color: rgba(79, 20, 75, 1);
        }
        &.color_m {
            background-color: rgba(252, 0, 248, 0.1);
            color: rgba(252, 0, 248, 1);
        }
        &.color_n {
            background-color: rgba(50, 90, 155, 0.1);
            color: rgba(50, 90, 155, 1);
        }
        &.color_o {
            background-color: rgba(255, 175, 22, 0.1);
            color: rgba(255, 175, 22, 1);
        }
        &.color_p {
            background-color: rgba(176, 96, 91, 0.1);
            color: rgba(176, 96, 91, 1);
        }
        &.color_q {
            background-color: rgba(145, 173, 28, 0.1);
            color: rgba(145, 173, 28, 1);
        }
        &.color_r {
            background-color: rgba(247, 34, 46, 0.1);
            color: rgba(247, 34, 46, 1);
        }
        &.color_s {
            background-color: rgba(142, 167, 162, 0.1);
            color: rgba(142, 167, 162, 1);
        }
        &.color_t {
            background-color: rgba(46, 217, 255, 0.1);
            color: rgba(46, 217, 255, 1);
        }
        &.color_u {
            background-color: rgba(177, 12, 161, 0.1);
            color: rgba(177, 12, 161, 1);
        }
        &.color_v {
            background-color: rgba(193, 116, 167, 0.1);
            color: rgba(193, 116, 167, 1);
        }
        &.color_w {
            background-color: rgba(254, 28, 191, 0.1);
            color: rgba(254, 28, 191, 1);
        }
        &.color_x {
            background-color: rgba(177, 0, 104, 0.1);
            color: rgba(177, 0, 104, 1);
        }
        &.color_y {
            background-color: rgba(128, 115, 10, 0.1);
            color: rgba(128, 115, 10, 1);
        }
        &.color_z {
            background-color: rgba(251, 0, 135, 0.1);
            color: rgba(251, 0, 135, 1);
        }
    }
}
.white_space_nowrap {
    white-space: nowrap;
    .bg-success {
        background-color: #08ad6c;
        width: 40px;
        color: #fff;
        height: 40px;
        &:hover {
            background-color: #048653;
        }
        .activity_icon {
            display: inherit;
            font-size: 20px;
            color: #fff !important;
        }

        .hover_icon {
            display: none;
            color: #fff !important;
        }
    }

    .wrap_icons_task {
        .activity_icon {
            display: inherit;
            font-size: 20px;
            color: #7467ef;
        }
        .hover_icon {
            display: none;
            color: #7467ef;
        }
        &:hover {
            .hover_icon {
                display: block;
            }

            .activity_icon {
                display: none;
            }
        }
    }
    .avatar_circle {
        display: inline-block;
        height: 36px;
        width: 36px;
        vertical-align: middle;
        text-align: center;
        line-height: 36px;
        color: $white;
        &.color_a {
            background-color: rgba(171, 12, 255, 0.1);
            color: rgba(171, 12, 255, 1);
        }
        &.color_b {
            background-color: rgba(50, 131, 255, 0.1);
            color: rgba(50, 131, 255, 1);
        }
        &.color_c {
            background-color: rgba(133, 102, 12, 0.1);
            color: rgba(133, 102, 12, 1);
        }
        &.color_d {
            background-color: rgba(86, 86, 86, 0.1);
            color: rgba(86, 86, 86, 1);
        }
        &.color_e {
            background-color: rgba(120, 42, 183, 0.1);
            color: rgba(120, 42, 183, 1);
        }
        &.color_f {
            background-color: rgba(28, 131, 86, 0.1);
            color: rgba(28, 131, 86, 1);
        }
        &.color_g {
            background-color: rgba(23, 255, 50, 0.1);
            color: rgba(23, 255, 50, 1);
        }
        &.color_h {
            background-color: rgba(50, 71, 102, 0.1);
            color: rgba(50, 71, 102, 1);
        }
        &.color_i {
            background-color: rgba(128, 128, 128, 0.1);
            color: rgba(128, 128, 128, 1);
        }
        &.color_j {
            background-color: rgba(28, 191, 78, 0.1);
            color: rgba(28, 191, 78, 1);
        }
        &.color_k {
            background-color: rgba(197, 68, 28, 0.1);
            color: rgba(197, 68, 28, 1);
        }
        &.color_l {
            background-color: rgba(79, 20, 75, 0.1);
            color: rgba(79, 20, 75, 1);
        }
        &.color_m {
            background-color: rgba(252, 0, 248, 0.1);
            color: rgba(252, 0, 248, 1);
        }
        &.color_n {
            background-color: rgba(50, 90, 155, 0.1);
            color: rgba(50, 90, 155, 1);
        }
        &.color_o {
            background-color: rgba(255, 175, 22, 0.1);
            color: rgba(255, 175, 22, 1);
        }
        &.color_p {
            background-color: rgba(176, 96, 91, 0.1);
            color: rgba(176, 96, 91, 1);
        }
        &.color_q {
            background-color: rgba(145, 173, 28, 0.1);
            color: rgba(145, 173, 28, 1);
        }
        &.color_r {
            background-color: rgba(247, 34, 46, 0.1);
            color: rgba(247, 34, 46, 1);
        }
        &.color_s {
            background-color: rgba(142, 167, 162, 0.1);
            color: rgba(142, 167, 162, 1);
        }
        &.color_t {
            background-color: rgba(46, 217, 255, 0.1);
            color: rgba(46, 217, 255, 1);
        }
        &.color_u {
            background-color: rgba(177, 12, 161, 0.1);
            color: rgba(177, 12, 161, 1);
        }
        &.color_v {
            background-color: rgba(193, 116, 167, 0.1);
            color: rgba(193, 116, 167, 1);
        }
        &.color_w {
            background-color: rgba(254, 28, 191, 0.1);
            color: rgba(254, 28, 191, 1);
        }
        &.color_x {
            background-color: rgba(177, 0, 104, 0.1);
            color: rgba(177, 0, 104, 1);
        }
        &.color_y {
            background-color: rgba(128, 115, 10, 0.1);
            color: rgba(128, 115, 10, 1);
        }
        &.color_z {
            background-color: rgba(251, 0, 135, 0.1);
            color: rgba(251, 0, 135, 1);
        }
    }
}
.text_ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    vertical-align: middle;
}
.text_link {
    color: var(--primary);
    &:hover {
        text-decoration: underline;
    }
}
.more_vert_menu {
    position: absolute;
    right: 0;
    top: 0;
}
.MuiFab-sizeSmall.small-size {
    width: 32px;
    height: 32px;
    min-height: 32px;
    box-shadow: none;
}
.list_user_profile {
    max-width: 250px;
    margin: 10px auto;
    h5 {
        color: var(--text-body);
        margin-bottom: 5px;
    }

    p {
        color: var(--text-muted);
        font-size: 14px;
        line-height: 18px;
        margin: 0;
    }
}

.user__menu {
    display: flex;
    justify-content: center;
    padding: 1rem 0;
}

.rightbar_vscroll {
    position: relative;
    height: calc(100vh - 330px);
    .MuiBox-root {
        height: 100%;
        position: relative;
        padding: 0;
        .v_scroll {
            height: 100%;
            padding-left: 16px;
            padding-top: 16px;
        }
        .MuiList-padding {
            li {
                padding: 0 0 0 10px;
            }
        }
    }
    .input_bx {
        > div {
            &:before {
                border-color: transparent;
            }
        }
    }
    .MuiInputBase-input {
        font: var(--font-input);
    }
    .sidebar_activity {
        list-style: none;
        padding: 0;
        margin: 0;
        .MuiAvatar-colorDefault {
            background-color: var(--text-muted);
            color: $white;
        }
        h6 {
            font-size: 15px;
            color: var(--text-body);
            margin-bottom: 5px;
        }
        p {
            color: var(--text-muted);
            font-size: 14px;
            line-height: 18px;
            margin: 0;
        }
        li {
            border-bottom: 1px solid #ebedf2;
            padding: 16px;
            position: relative;
            &:hover {
                .activity_action {
                    display: block;
                }
            }
        }
    }
    .activity_summary {
        border-top: 1px solid #ebedf2;
        border-bottom: 1px solid #ebedf2;
        margin-top: 16px;
        .MuiGrid-item {
            border-right: 1px solid #ebedf2;
            padding: 5px;
            p {
                font-size: 14px;
            }
        }
    }
    .Google_activity_summary {
        border-top: 1px solid #ebedf2;
        border-bottom: 1px solid #ebedf2;
        margin-top: 5px;
        .MuiGrid-item {
            border-right: 1px solid #ebedf2;
            padding: 5px;
            p {
                font-size: 14px;
            }
        }
    }
}
.rightbar_vscroll_task {
    position: relative;
    height: calc(100vh - 220px);
}
.rightbar_related {
    .MuiCard-root {
        box-shadow: none;
        border-bottom: 1px solid #ebedf2;
        margin-top: 0 !important;
        border-radius: 0;
        .box_portlet {
            border: 0;
            .box_body {
                .opp_name {
                    max-width: 170px;
                }
                padding: 0 !important;
                .hover_show {
                    position: absolute;
                    opacity: 0;
                    right: -5px;
                    top: -5px;
                }
                &:hover .hover_show {
                    opacity: 1;
                }
            }
        }
    }
}
.activity_action {
    position: absolute;
    display: none;
    right: 10px;
    top: 10px;
    z-index: 1;
    background-color: #fff;
    padding: 5px 10px;
    border-radius: 32px;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 1px 6px rgba(0, 0, 0, 0.12);
}
.custom_dialog {
    top: auto !important;
    width: 750px;
    margin: 0 auto;
    .MuiDialog-paperWidthMd {
        max-width: 750px;
        margin-bottom: 0;
        border-radius: 8px 8px 0 0;
        box-shadow: 0 1px 24px 0 rgba(0, 0, 0, 0.5);
    }
    .MuiDialog-container {
        height: inherit;
    }
    .MuiDialog-scrollPaper {
        align-items: flex-end;
    }
    .MuiBackdrop-root {
        display: none;
    }
    &.alignitemsend {
        top: calc(100% - 120px) !important;
        max-width: 415px;
        .MuiDialog-paperWidthMd {
            position: absolute;
            top: calc(100% - 88px);
            max-width: 350px;
        }
        .dialog-body,
        .dialog-action {
            display: none;
        }
        .button_hover:hover {
            background-color: #0081D8 !important;
        }
    }
    &.alignitemsend-fullscreen {
        .MuiDialog-paperWidthMd {
            max-width: 350px;
        }
    }
    &.fullscreen {
        top: 0 !important;
        width: auto;
        .MuiDialog-paperWidthMd {
            max-width: 80%;
            margin-bottom: 0;
            border-radius: 8px;
        }
        .MuiDialog-scrollPaper {
            align-items: center;
        }
        .MuiDialog-container {
            height: 100%;
        }
        .MuiBackdrop-root {
            display: block;
        }
    }
    .rotate-45 {
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
    }
    .dialog-body {
        padding: 5px 16px;
        border-top: 1px solid rgba(0, 0, 0, 0.12);
        border-bottom: 1px solid rgba(0, 0, 0, 0.12);
        flex: 1 1 auto;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        max-height: calc(100vh - 200px);
        .MuiInputBase-input {
            font-size: 15px;
            color: var(--text-body);
        }
    }
    .dialog-body-no-scroll {
        padding: 5px 16px;
        border-top: 1px solid rgba(0, 0, 0, 0.12);
        border-bottom: 1px solid rgba(0, 0, 0, 0.12);
        flex: 1 1 auto;
        overflow-y: hidden;
        -webkit-overflow-scrolling: touch;
        max-height: calc(100vh - 200px);
        .MuiInputBase-input {
            font-size: 15px;
            color: var(--text-body);
        }
    }
    .dialog-action {
        padding: 16px;
    }
    .button_hover:hover {
        background-color: #0081D8 !important;
    }
}
.input_bx {
    .MuiInputLabel-animated {
        font-size: 12px;
        font-weight: 500;
        color: var(--text-muted);
        &.MuiFormLabel-filled,
        &.Mui-focused {
            transform: translate(0, 1.5px) scale(0.98);
        }
    }
    .dorpdown_menu_list {
        padding: 0;
        margin: 0;
        p {
            cursor: pointer;
            color: var(--text-muted);
            font-size: 12px;
            font-weight: 500;
        }
        li {
            padding: 0;
        }
        .material-icons {
            color: var(--text-muted);
            font-size: 18px;
        }
    }
}
.multiple-text-dropdown {
    font-size: 12px;
    padding-right: 25px;
    outline: none;
    // padding-bottom: 0px;
    font-weight: 500;
    color: #7c8182;
    cursor: pointer;
}

.add-more-field {
    float: right;
    color: #7467ef;
    font-size: 12px;
    cursor: pointer;
}
.checkbox-margin-18 {
    margin-top: 18px !important;
}
.focused_size{
    max-width: 208px !important;
}
.sidebar_activity.crm-leads-sidebar-logs-list {
    max-height: calc(100vh - 450px);
    overflow: auto;
}
.sidebar_activity.crm-leads-sidebar-logs-list-task {
    max-height: calc(100vh - 320px);
    overflow: auto;
}
.crm-add-contat-company-input {
    z-index: 5 !important;
}
.lead-filter-error {
    font-size: 13px;
    color: red;
    margin-right: 35px !important;
}
.custom-avatar-sidebar {
    cursor: pointer !important;
    height: 4rem !important;
    width: 4rem !important;
    border-radius: 50% !important;
    margin-bottom: 8px !important;
    max-width: 100% !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
}
.loaderPopupCenter {
    display: inline-block !important;
    vertical-align: middle !important;
    text-align: center !important;
    top: 50% !important;
    position: absolute !important;
    left: 50% !important;
}

.popupHeightCustom {
    height: 400px !important;
}
.pipline-selected-class {
    background-color: #fafafa !important;
    color: #7467ef !important;
}

.crm-email-log-attachment {
    border: 1px solid rgba(0, 0, 0, 0.6);
    border-radius: 15px;
    background-color: rgba(0, 0, 0, 0.06);
    cursor: pointer;
    box-shadow: none !important;
    margin: 5px !important;
    font-size: small;
}

// .crm_log_checkbox_filter {
//     position: absolute !important;
//     right: 10px !important;
// }

.crm_log_checkbox_filter {
    position: absolute !important;
    right: 20px !important;
    &:checked {
        color: #fff !important;
        &:hover {
            color: #fff !important;
        }
    }
}

.visibleStarIcon {
    opacity: 1 !important;
    width: auto !important;
}

.crm_activitytype_icon {
    font-size: "15px";
    color: "#7467ef";

    // &:hover {
    //     color: #ffffff;
    // }
}

.wrap_icons_task_sidebar {
    box-shadow: none !important;

    .activity_icon {
        display: inherit;
        font-size: 20px;
        color: #7467ef;
    }

    .hover_icon {
        display: none;
        color: #7467ef;
    }

    &:hover {
        .hover_icon {
            display: block;
        }

        .activity_icon {
            display: none;
        }
    }
}
.crm-custom-notification-settings,
.crm-custom-importdata-settings {
    height: calc(100% - 20px);

    .default-template-link {
        color: var(--primary);
    }

    .import-buttons {
        .types-buttons {
            height: 60px;
            width: 140px;
        }
    }

    .crm-custom-notification-table {
        .antd-tables {
            thead,
            th,
            td {
                box-shadow: none !important;
            }

            td {
                border: none !important;
                // padding-bottom: 20px !important;
            }

            .align_left {
                text-align: left !important;
            }
        }
    }

    .MuiSnackbar-anchorOriginBottomLeft {
        left: auto !important;
    }
}

.crm_dialog_setting {
    .clear_icon {
        margin: -65px 0 0 545px;
    }
    .clear_icon2 {
        margin: -65px 0 0 900px;
    }
    .MuiPaper-rounded {
        border-radius: 0;
    }
    .activeTag {
        border-radius: 14px;
        height: 21px;
        width: 300px;
        display: flex;
    }
}

.crm-custom-Integrations-settings,
.v_scrollI {
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
}
.IntegrationBlock_content {
    display: flex;
    flex-wrap: wrap;
}
.IntegrationCard_title {
    color: #fff;
    width: 220px;
}
.text-warp {
    white-space: nowrap;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
}
.border_none {
    border: none;
}
.itergation_logo {
    flex: 0 0 auto;
    height: 50px;
    width: 50px;
    border-radius: 10px;
    margin-right: 15px;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
}
.itergation_logo_size {
    width: 40px;
    height: 40px;
}
.IntegrationBlock_contents {
    display: flex;
    flex-wrap: wrap;
    padding: 20px 0 0 10px;
}
.Integrations_card {
    border-radius: 2px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
    background-color: #fff;
    border-top: 1px solid #dce3e6;
    margin: 0 20px 20px 0;
    flex: 0 0 auto;
    width: 330px;
    overflow: hidden;
}
.Zapier_logo {
    flex: 0 0 auto;
    height: 50px;
    width: 50px;
    border-radius: 10px;
    margin: 20px;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
}
.zendesk_Integrations_card {
    border-radius: 2px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
    background-color: #fff;
    border-top: 1px solid #dce3e6;
    margin: 0 20px 20px 0;
    flex: 0 0 auto;
    width: 99%;
    overflow: hidden;
}
.Zapier_Integrations_card {
    border-radius: 2px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
    background-color: #fff;
    border-top: 1px solid #dce3e6;
    margin: 0 20px 20px 0;
    flex: 0 0 auto;
    width: 98%;
    overflow: hidden;
}
.tooltip {
    position: relative;
    display: inline-block;
    border: none;
    width: 900px;
}

.tooltip .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    top: 150%;
    left: 50%;
    margin-left: -60px;
}

.tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent black transparent;
}

.tooltip:hover .tooltiptext {
    visibility: visible;
}
.Zend_Integrations_card {
    color: #888888;
}
.IntegrationCard_activeTag {
    border-radius: 14px;
    padding: 3px;
    background: #07a661;
    height: 21px;
    width: 200px;
    margin: -10px -12px 0px -35px;
    display: flex;
}
.ActiveFont {
    font-size: 10px;
    font-weight: 600;
    color: #fff;
    // padding: 1px 0 0 12px;
}

.Alloy-CardSection-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 60px;
    padding-right: 8px;
}
.active__button {
    height: 2px;
    width: 2px;
    -moz-border-radius: 70px;
    -webkit-border-radius: 70px;
    background-color: #eaf0f2;
    border-radius: 50%;
    background-color: white;
    margin: 5px;
}
.QuickBooksIntegrationCard_connectButton {
    width: 230px;
    height: 40px;
    // background-image: url('./quickbooks-logo-horz.png');
    background-size: contain;
    display: block;
    margin: 10px;
    background-color: green;
}
.Btn-flatSecondary {
    background-color: transparent;
    color: #6d82ec;
}
.Btn-flatPrimary {
    background-color: transparent;
    color: #2b41b2;
}
.Btn:hover {
    text-decoration: none;
}
.Btn {
    letter-spacing: 0.5px;
    border-radius: 16px;
    border: none;
    cursor: pointer;
    display: inline-block;
    font-family: Roboto, sans-serif;
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    padding: 6px 16px;
    text-align: center;
    transition: background-color 0.2s, opacity 0.2s;
    outline: 0;
    position: relative;
    overflow: hidden;
}
.IntegrationBlock_contents {
    display: flex;
    flex-wrap: wrap;
}

.crm-calendar-event {
    a {
        color: var(--primary) !important;
    }
}

.crm-custom-suggestion-autocomplete {
    .custom-label,
    .custom-email {
        font-size: 12px !important;
    }
}

// email template editor
.crm_dialog_create_template {
    // .MuiPaper-root {
    //     overflow: visible !important;
    // }
    .megadraft-editor {
        .paragraph + .paragraph {
            margin: 0 !important;
        }
        .sidebar__menu {
            float: right !important;
            left: unset !important;
            right: 20px !important;
            z-index: 10 !important;
            box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.2) !important;
            border-radius: 25px !important;
            padding-left: 3px !important;
            padding-right: 3px !important;
            background-color: #fff !important;
        }
        .toolbar__button {
            height: auto !important;
        }
    }
    .MuiFormControl-root {
        width: 100% !important;
    }
}
.subject-editor {
    .DraftEditor-root {
        max-height: 50px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.5);
        font-size: 13px !important;
        overflow: auto;
        font-family: $font-family-base !important;
    }
    .public-DraftEditorPlaceholder-root{
        color : #c2c0c0 !important;
        font-size : 13px !important;
        font-family: $font-family-base !important;
    }
    .megadraft-editor .DraftEditor-root {
        font-family: Roboto, Arial, Helvetica, sans-serif  !important;
        font-size: 0.8125rem !important;
        letter-spacing: 0 !important;
        line-height: 1.75rem !important;
        position: relative;
      }
}

.wrapper-rec {
    &:hover {
        background-color: #dce3e6 !important;
        .p_value {
            color: black !important;
        }
    }
    .crm-rec-hashplaceholder {
        padding-top: 2px !important;
        padding-bottom: 2px !important;
        padding-left: 10px !important;
        padding-right: 10px !important;
        background-color: #dee3ff !important;
        color: #1e3192 !important;
        font-size: 12px !important;
        border-radius: 30px !important;
    }
}

.wrapper-send {
    &:hover {
        background-color: #dce3e6 !important;
        .p_value {
            color: black !important;
        }
    }

    .crm-send-hashplaceholder {
        padding-top: 2px !important;
        padding-bottom: 2px !important;
        padding-left: 10px !important;
        padding-right: 10px !important;
        background-color: #defff1 !important;
        color: #07a661 !important;
        font-size: 12px !important;
        border-radius: 30px !important;
    }
}

a[title*="rec"] {
    padding-top: 2px !important;
    padding-bottom: 2px !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
    background-color: #dee3ff !important;
    color: #1e3192 !important;
    font-size: 12px !important;
    border-radius: 30px !important;
}
a[title*="send"] {
    padding-top: 2px !important;
    padding-bottom: 2px !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
    background-color: #defff1 !important;
    color: #07a661 !important;
    font-size: 12px !important;
    border-radius: 30px !important;
}

.message-editor {
    .megadraft-editor .DraftEditor-root {
        font-family: Roboto, Arial, Helvetica, sans-serif  !important;
        font-size: 0.8125rem !important;
        letter-spacing: 0 !important;
        line-height: 1.75rem !important;
        position: relative;
      }
    .DraftEditor-root {
        min-height: 100px;
        max-height: 30px;
        font-size: 13px !important;
        overflow: auto;
        font-family: $font-family-base !important;
    }
    .public-DraftEditorPlaceholder-root{
        color : #c2c0c0 !important;
        font-size : 13px !important;
        font-family: $font-family-base !important;
    }
}
.Scroll_upload {
    min-height: 150px;
    overflow: auto;
}
//-------------

.crm-editor-mention {
    box-sizing: border-box;
    border-bottom: 1px solid rgba(0, 0, 0, 0.45);
    cursor: text;
    padding: 0px !important;
    //   border-radius: 2px;
    margin-bottom: 2em;
    //   box-shadow: inset 0px 1px 8px -3px #ABABAB;
    //   background: #fefefe;
    .public-DraftEditorPlaceholder-inner{
        // margin-bottom : '0px 0 -18px 0' !important;
        color : #c2c0c0 !important;
        font-size : 13px !important;
        font-family: $font-family-base !important;
        margin: 0 0 -18px 0 !important;
    }
}
.crm-editor-mention :global(.public-DraftEditor-content) {
    min-height: 140px;
    font-family: $font-family-base !important;
}

.draftJsMentionPlugin__mention__29BEd {
    // color: #4a85bb !important;
    color: #1a2038 !important;
    background-color: var(--bg-default) !important;
}

///---------------------design feedback-----------------------------
.ant-empty.ant-empty-normal {
    text-align: center !important;
    margin-top: 20px !important;
}

.MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom: 1px solid #dce3e6 !important;
}

.MuiInputLabel-animated {
    font-size: 12px !important;
    color: #696e6f !important;
}
.MuiInput-input.MuiInputBase-input {
    font-size: 13px !important;
    // color: #696E6F !important;
}
.crm-custom-bg-primary {
    .text-white {
        color: #000 !important;
    }
    .bg-primary {
        background-color: #f5f5f5 !important;
    }
}
.emoji_counter {
    padding: 8px;
    box-sizing: content-box;
    right: 20px;
    top: 14px;
    flex-flow: row wrap;
    width: 100%;
    font-size: 18px;
    background-color: white;
    li {
        width: 45px;
        height: 32px;
        line-height: 25px;
        font-size: 18px;
        text-align: center;
        cursor: pointer;
        padding: 3px !important;
        display: inline-block;
        border-bottom: none !important;
        position: unset !important;
        &:hover {
            background-color: #dce3e6;
            border-radius: 25px;
        }
    }
}

.emoji_popup {
    width: 140px;
    font-size: 18px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 2px 23px 3px rgba(212, 212, 212, 1);
    li {
        width: 25px;
        height: 25px;
        line-height: 25px;
        font-size: 18px;
        text-align: center;
        cursor: pointer;
        padding: 3px !important;
        display: inline-block;
        border-bottom: none !important;
        position: unset !important;
        &:hover {
            background-color: #dce3e6;
            border-radius: 25px;
        }
    }
}

//import data files
.crm-importdata-table-wrapper {
    .TableContainer-root-352 {
        max-height: calc(100vh - 225px);
    }
}

.import_fix_field_button {
    top: 0px;
    color: #ff0000 !important;
    left: -20px;
    position: absolute;
    padding: 0px !important;
    max-width: 20px !important;
}

.crm-container-import {
    width: 97%;
    .crm-container-import-modules {
        height: auto;
        padding-left: 28px;
        padding-right: 20px;
        padding-top: 5px;
        padding-bottom: 5px;
        white-space: nowrap;
        overflow-y: hidden;
        overflow-x: scroll;
        -webkit-overflow-scrolling: touch;
    }
    /* Hide scrollbar for Chrome, Safari and Opera */
    .crm-container-import-modules::-webkit-scrollbar {
        display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .crm-container-import-modules {
        -ms-overflow-style: none;
        /* IE and Edge */
        scrollbar-width: none;
        /* Firefox */
    }
    #free-solo-2-demo {
        width: 140px;
    }
    .module {
        display: inline-block;
        width: 165px;
    }

    .module + .module {
        margin-left: 5px;
    }
}
.whiteSpaceInitial {
    white-space: initial;
}
.toolbar__wrapper {
    position: absolute !important;
}
.import-validation-repeat-message {
    color: red !important;
    font-size: 11px;
}
.public-DraftEditorPlaceholder-root {
    color: #696e6f !important;
    font-size: 13px !important;
    font-family: $font-family-base !important;
}
.compose_fieldName {
    width: 28px;
    font-size: 12px;
    color: #696e6f;
    align-items: center;
    line-height: 30px;
    text-transform: uppercase;
    flex-shrink: 0;
    font-weight: 400;
}
.compose_ext_name {
    font-size: 12px;
    display: inline-block;
    text-transform: uppercase;
    margin-left: 5px;
    cursor: pointer;
    font-weight: 400;
    color: #696e6f;
    &:hover {
        color: var(--primary);
    }
}
.compose_ext_name_lowerCase {
    font-size: 12px;
    display: inline-block;
    margin-left: 5px;
    cursor: pointer;
    font-weight: 400;
    color: #696e6f;
    &:hover {
        color: var(--primary);
    }
}

.megadraft-editor .toolbar__arrow {
    display: none !important;
}
.email_error {
    .MuiInput-underline:before {
        .MuiInput-underline:before {
            border-bottom: 2px solid red !important;
        }
        .Mui-focused {
            &::after {
                border-bottom: 2px solid red !important;
            }
        }
    }
}

.tracking_popover {
    background-color: rgb(255, 255, 255);
    min-width: 300px;
    box-shadow: rgba(0, 0, 0, 0.16) 1px 2px 5px 1px, rgba(0, 0, 0, 0.12) 1px 1px 6px;
    position: absolute;
    margin-left: -15px;
    top: auto;
    margin-top: 35px;
    z-index: 2;
    .avatar_circle {
        font-size: 16px;
    }
    .show_status {
        font-size: 16px;
        font-weight: 500;
        background-color: var(--primary);
        color: white !important;
    }
    .hide_status {
        font-size: 16px;
        font-weight: 500;
        background-color: white;
        color: black;
        border-bottom: 1px solid #cccccc;
    }
    .tracking_list {
        max-height: 100px;
        overflow-y: auto;
        overflow-x: hidden;
        p {
            color: #0c0d0d;
            padding: 0px 10px;
        }
    }
    hr {
        border-top: 1px solid #dce3e6;
        margin-left: 5px;
        margin-right: 5px;
    }
    .tracking_footer {
        padding: 0px 5px 9px 5px !important;
        text-align: right;
    }
}
.track_icon {
    position: relative;
    bottom: 16px;
    right: -20px;
    border-radius: 25px;
    width: 33px;
    border: 2px solid white;
    background: var(--primary);
    cursor: pointer;
    height: 22px;
    padding: 2px;
    .MuiSvgIcon-root {
        color: white;
        font-size: 13px;
        margin-bottom: 1px;
    }
    .track_count {
        top: 0px;
        left: 16px;
        width: -1px;
        font-size: 11px;
        font-weight: bold;
        line-height: 20px;
        color: white;
        position: absolute;
    }
}
.action_menu {
    position: absolute;
    display: block;
    right: 5px;
    z-index: 1;
    background-color: #fff;
    border-radius: 32px;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 1px 6px rgba(0, 0, 0, 0.12);
    .MuiIconButton-sizeSmall {
        padding: 5px;
    }
}
.track_icon_unread {
    position: relative;
    bottom: 16px;
    right: -20px;
    border-radius: 25px;
    width: 33px;
    border: 1px solid var(--primary);
    background: white;
    cursor: pointer;
    height: 20px;
    padding: 2px;
    .MuiIcon-root {
        color: var(--primary);
        font-size: 13px;
        margin-bottom: 1px;
    }
    .track_count {
        top: 0px;
        left: 17px;
        width: -1px;
        font-size: 11px;
        font-weight: bold;
        line-height: 20px;
        position: absolute;
        color: var(--primary);
    }
}
.report_opportunity_filter {
    .v_scroll {
        height: calc(100vh - 200px);
    }
}

.isTableEmpty {
    height: 330px;
    .list_empty_crm {
        border: 0;
        background: 0 0;
        position: absolute;
        top: 250px;
        left: 50%;
        -ms-transform: translate(-50%, -50%);
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        .noListIcon {
            height: 128px;
            width: 128px;
            border-radius: 50%;
            background-color: #eeedf7;
            color: #fff;
            padding: 10px 0;
            font-size: 90px;
            margin: 10px auto;
            text-align: center;
            .MuiIcon-root {
                font-size: inherit;
            }
        }
        .noListTitle {
            color: var(--text-hint) !important;
            font-size: 30px;
            font-weight: 700;
            margin: 10px auto;
            text-align: center;
        }
    }
}


.isLogTabEmpty {
    height: 330px;
    .tab_empty_crm {
        border: 0;
        background: 0 0;
        position: absolute;
        top: 250px;
        left: 50%;
        -ms-transform: translate(-50%, -50%);
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        .notabIcon {
            height: 80px;
            width: 80px;
            border-radius: 50%;
            background-color: #eeedf7;
            color: #fff;
            // padding: 10px 0;
            font-size: 90px;
            margin: 10px auto;
            text-align: center;
        }
        .no_icon{
            font-size: 50px !important;
            margin: 10px 10px 34px 10px;
        }
        .notabTitle {
            color: var(--text-hint) !important;
            font-size: 22px;
            font-weight: 700;
            margin: 10px auto;
            text-align: center;
        }
    }
}


.edit_visibilty {
    .hover_show {
        display: none;
    }
    &:hover .hover_show {
        display: block;
    }
}
.company_user_name {
    &:hover {
        text-decoration: underline;
    }
}

.visibility_radio {
    .MuiIconButton-root {
        transition: none !important;
    }
    .MuiIconButton-colorPrimary {
        &:hover {
            background-color: transparent !important;
        }
    }
    .MuiRadio-colorPrimary.Mui-checked {
        &:hover {
            background-color: transparent !important;
        }
    }
    .MuiSvgIcon-root {
        width: 1rem;
        height: 1rem;
    }
}

//SingleSelect

// .css-1hivdsp-control {
//     margin-right: 0px !important;
//     min-height: unset !important;
//     border-bottom: 2px solid #3f51b5 !important;
//     &:hover {
//         background: none !important;
//         border-bottom: 2px solid #3f51b5 !important;
//     }
// }
// .css-hr4vws-control {
//     margin-right: 0px !important;
//     min-height: unset !important;
//     background: none !important;
//     border-bottom: none !important;
//     &:hover {
//         background: none !important;
//         border-bottom: none !important;
//         cursor: auto;
//     }
// }
// .css-1wa3eu0-placeholder {
//     font-size: 13px;
//     padding: 0;
//     margin: 0;
//     bottom: 0;
//     letter-spacing: inherit;
//     color: #c8c8c8;
//     font-weight: 300;
// }
// .css-ikt7kq-IndicatorsContainer {
//     display: none !important;
// }
// .css-1okebmr-indicatorSeparator {
//     display: none !important;
// }
// // end SingleSelect

// .css-11hpcgx-control {
//     margin-right: 0px !important;
//     min-height: unset !important;
//     &:hover {
//         background: none !important;
//         border-bottom: 1px solid #dce3e6 !important;
//     }
// }
// .has_edit {
//     .css-11hpcgx-control {
//         border-bottom: none !important;
//     }
//     &:hover {
//         background: none !important;
//         border-bottom: 1px solid #dce3e6 !important;
//         cursor: auto;
//     }
//     .css-1wa3eu0-placeholder {
//         color: #c8c8c8;
//         font-weight: 300;
//     }
// }
// .css-107lb6w-singleValue {
//     font-size: 13px !important;
//     color: var(--text-body) !important;
// }

div[class*="-placeholder"] {
    font-size: 13px !important;
    bottom: 0px !important;
    letter-spacing: inherit !important;
    font-weight: lighter !important;
    padding: 0px !important;
    margin: 0px !important;
    color: #a6a6a6 !important;
}
div[class*="-control"] {
    margin-right: 0px !important;
    min-height: unset !important;
    &:hover {
        background: none !important;
        border-bottom: 1px solid #dce3e6 !important;
    }
}
span[class*="-indicatorSeparator"] {
    display: none !important;
}

div[class*="-indicatorContainer"] {
    color: rgb(204, 204, 204);
    display: flex;
    padding: 0px;
    transition: color 150ms ease 0s;
    box-sizing: border-box;
    margin: 0px !important;
    cursor: pointer;
    svg {
        top: 50%;
        right: 30px;
        position: absolute;
        width: 0px;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 5px solid rgb(117, 117, 117);
    }
}
.has_edit {
    div[class*="-control"] {
        font-size: 13px !important;
        border-bottom: none !important;
        &:hover {
            background: none !important;
            border-bottom: 1px solid #dce3e6 !important;
            cursor: auto;
        }
    }
}

//-------------------------------------------------------------------

/* These styles are used if a demo specific stylesheet is not present */

// *,
// *:before,
// *:after {
//   box-sizing: border-box;
// }

// body,
// html {
//   background-color: #f6f9fc;
//   font-size: 18px;
//   font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
// }

// form {
//   max-width: 800px;
//   margin: 80px auto;
// }

// label {
//   color: #6b7c93;
//   font-weight: 300;
//   letter-spacing: 0.025em;
//   margin-top: 16px;
//   display: block;
// }

// button {
//   white-space: nowrap;
//   border: 0;
//   outline: 0;
//   display: inline-block;
//   height: 40px;
//   line-height: 40px;
//   padding: 0 14px;
//   box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
//   color: #fff;
//   border-radius: 4px;
//   font-size: 15px;
//   font-weight: 600;
//   text-transform: uppercase;
//   letter-spacing: 0.025em;
//   background-color: #6772e5;
//   text-decoration: none;
//   -webkit-transition: all 150ms ease;
//   transition: all 150ms ease;
//   margin-top: 10px;
// }

// button:hover {
//   color: #fff;
//   cursor: pointer;
//   background-color: #7795f8;
//   transform: translateY(-1px);
//   box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
// }

// button[disabled] {
//   opacity: 0.6;
// }

// input {
//   display: block;
//   border: none;
//   font-size: 18px;
//   margin: 10px 0 20px 0;
//   max-width: 100%;
//   padding: 10px 14px;
//   box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px,
//     rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
//   border-radius: 4px;
//   background: white;
//   color: #424770;
//   letter-spacing: 0.025em;
//   width: 500px;
// }

// input::placeholder {
//   color: #aab7c4;
// }

// .result,
// .error {
//   font-size: 16px;
//   font-weight: bold;
//   margin-top: 10px;
//   margin-bottom: 20px;
// }

// .error {
//   color: #e4584c;
// }

// .result {
//   color: #666ee8;
// }

// /*
// The StripeElement class is applied to the Element container by default.
// More info: https://stripe.com/docs/stripe-js/reference#element-options
// */

// .StripeElement,
// .StripeElementIdeal,
// .StripeElementFpx {
//   display: block;
//   margin: 10px 0 20px 0;
//   max-width: 500px;
//   padding: 10px 14px;
//   box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px,
//     rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
//   border-radius: 4px;
//   background: white;
// }

// .StripeElement--focus,
// .StripeElementIdeal--focus,
// .StripeElementFpx--focus {
//   box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px,
//     rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
//   -webkit-transition: all 150ms ease;
//   transition: all 150ms ease;
// }

// .StripeElement.loading {
//   height: 41.6px;
//   opacity: 0.6;
// }

// .StripeElementIdeal,
// .StripeElementFpx {
//   padding: 0;
// }

.workflow_editor {
    .DraftEditor-editorContainer {
        padding-right: 25%;
    }
}
.btn_Primary:hover {
    background-color: #0081D8 !important;
}
.checkB_color {
    color : #0081D8 !important;
    &:hover {
        color : white !important;
    }
}

.hover_name {
    a {
        &:hover {
            text-decoration: underline;
            color: var(--text-body);
        }
    }
}

.report_layout{
    @include media-below(xl) {
        overflow-x: auto !important;
    }
}
.report_layout2{
    @include media-below(xl) {
        width : 1040px !important;
    }
}

