// display
.overflow-auto {
  overflow: auto !important;
}
.overflow-hidden {
  overflow: hidden;
}
.scroll-y {
  overflow-x: hidden;
  overflow-y: scroll;
}

// postions
.position-relative {
  position: relative;
}
.position-bottom {
  position: absolute;
  bottom: 0;
}
.text-center {
  text-align: center;
}
.text-middle {
  vertical-align: middle;
}
.text-align-right {
  text-align: right;
}
.text-align-left {
  text-align: left;
}
.x-center {
  left: 50%;
  transform: translateX(-50%);
}
.y-center {
  top: 30%;
  transform: translateY(-50%);
}

// misc
.cursor-pointer {
  cursor: pointer;
}
.cursor-move {
  cursor: move;
}

.people_position {
  height: 2rem;
  width: 2rem;
  border-radius: 50%;
  margin-bottom: -5px;
  max-width: 75%;
}