.opportunities_card{
    .aside_left{
        bottom: 80px;
    }
    .fixed_layout{
        bottom: 80px;
    }
    &.progression-report{
        .aside_left,.fixed_layout{
            bottom: 16px;
        }
        .text-value{
            font-size: 18px;
            font-weight: 700;
            text-align: left;            
        }
        .progression_stag{
            margin-top: 15px;
            border-bottom: 1px solid #ebedf2;
            padding-bottom: 8px;
            .stag_cell{
                position: relative;
                padding: 10px 16px;
                height: 65px;                
                border-bottom: 1px solid #ebedf2;
                border-top: 1px solid #ebedf2;
                overflow: hidden;
                &::before, &::after{
                    content: '';                    
                    border-right: 13px solid transparent;
                    border-bottom: 37px solid transparent;
                    border-top: 37px solid transparent;
                    position: absolute;
                    right: 0;
                    top: -5px;
                }
                &::before{
                    border-left: 13px solid #fff;
                    right: 2px;
                    z-index: 1;
                }
                &::after{
                    border-left: 13px solid #ebedf2;  
                }
                &:last-child{
                    &::before, &::after{
                        display: none;
                    }  
                }
                .title{
                    font-size: 13px;
                    font-weight: 700;
                    text-transform: uppercase;
                    line-height: 21px;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
                .total{
                    font-size: 12px;
                    color: var(--text-muted);
                    font-weight: 500;
                }
            }
            .text-lable{
                font-size: 11px;
                font-weight: 700;
                margin-bottom: 5px;
            }
            .text-value{
                font-size: 12px;
                // text-align: center;
                text-align: left;
                margin: 4px;
            }
        }
        .stag_body{
            position: relative;
            margin-top: 16px;
            &.v_scroll{
                height: calc(100% - 178px);
            }
            .deal_line{
                position: relative;  
                margin-bottom: 16px;
                .rounded_icon{
                    position: absolute;
                    background: #ebedf2;
                    display: inline-block;
                    border-radius: 50%;
                    line-height: 20px;
                    text-align: center;
                    width: 24px;
                    height: 24px;
                    top: 50%;
                    left: 10%;
                    margin: -12px;
                    color: #FFF;
                }
                hr
                {
                    top: 50%;
                    position: absolute;
                    display: block;
                    height: 1px;
                    border-top: 1px dashed #ebedf2;
                    border-width: 1px 0 0 0;
                    left: 10%;
                    margin: 0;
                }
                .deal_details{
                    position: relative;
                    height: 100%;
                    padding: 10px;
                    border: 1px solid #DCE3E6;
                    box-shadow: 0 1px 1.5px 0 rgba(0, 0, 0, 0.12);
                    border-radius: 3px;
                    background: #fff;
                    width: calc(20% - 32px);
                    .deal_title{
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        position: relative;
                        display: inline-block;
                        font-size: 16px;
                        font-weight: 500;
                        line-height: 24px;
                        color: var(--text-body);
                        cursor: pointer;
                        max-width: calc(100% - 15px);
                    }
                    .account_assignee,.monetary_value
                    {
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        font-size: 12px;
                        line-height: 20px;
                        color: var(--text-body);
                    }
                }
            }
            .first{
                hr{
                    width: 0%;
                }
                .deal_details{
                    left: 16px;
                }  
            }
            .second{
                hr{
                    width: 21%;
                }
                .deal_details{
                    left: 21%;
                }  
            }
            .third{
                hr{
                    width: 41%;
                }
                .deal_details{
                    left: 41%;
                }  
            }
            .forth{
                hr{
                    width: 61%;
                }
                .deal_details{
                    left: 61%;
                }  
            }
            .fifth{
                hr{
                    width: 81%;
                }
                .deal_details{
                    left: 81%;
                }
            }
        }
    }
    .aside_left{
        h5{
            border-top: 1px solid #ebedf2;
            padding:12px 16px 5px;
            margin: 0;
            height: 45px;
            &:hover{
                background-color:#fafafa; 
            }
            &:first-child{
                border-top: 0
            }
        }
    }
    .fixed_height{
        bottom: 0;
        top: 165px;
        &.pipelines_filters_collapse{
            top: 460px;
        }
        &.pipelines_collapse{
            top: 298px;
        }
        &.filters_collapse{
            top: 328px;
        }
    }
    .pipline_stats {
        position: fixed;
        bottom: 10px;
        left: 16px;
        right: 16px;
        top: calc(100% - 64px);
        z-index: -1;
        padding:0 16px;
        .border_bx{
            margin-top: 0;
            border-width: 0 0 0 2px;
            padding:5px 10px;
        }
    }
    .pipline_tags{
        background-color:var(--bg-default);
        padding: 16px;
        position: fixed;
        bottom: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 1;
    }

    .bg_error,.bg_white,.bg_green{
        padding:30px 25px;
        z-index:99999 !important;
        font-size: 22px;
        color: #fff;
        font-weight: 700;
        text-transform: uppercase;
        text-align: center;
        background-color:rgba(244,67,54,.8);
        &.bg_white{
            background-color:rgba(255,255,255,.8);
            color: var(--text-body);
        }
        &.bg_green{
            background-color:rgba(8,173,108,.8);
        }
    }
}

.opportunities-dnd{
    height: calc(100vh - 225px);
    position: relative;
    margin-top: 10px;
    padding: 0 16px;    
    .title-with-label{
        .MuiSwitch-colorPrimary{
            &.Mui-checked{
                color: #08ad6c;
            }
        }
        .MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track {
            background-color:#08ad6c;
        }
        .MuiSwitch-colorPrimary.Mui-checked:hover {
            background-color:rgba(8,173,108, 0.08);
        }
        .MuiFormControlLabel-label{
            font-size: 14px;
        }
        .MuiInput-formControl{
            height: 40px;
            color: var( --text-muted);
        }
    }
    .react-trello-board{
        height: 93%;
        background-color: #fff;
        padding: 0;
        overflow: auto;
        > div, .smooth-dnd-container{
            height: 100%;
        }
        .fixed-bottom{
            position: absolute;
            left: 0;
            right: 0;
            bottom: 10px;
            margin-bottom: 0;
            border: 0;
            border: 1px solid #DCE3E6;  
            cursor: text; 
            background-color: #fafafa;
            &:hover{
                background-color: #fafafa;
            }
            header{  
                margin-bottom: 0;
                border-bottom: 0;
                padding: 0;     
                display: block;   
                cursor: text; 
                .gDooAJ{
                    display: none;
                }                
                span{                    
                    cursor: text;
                }
            }
        }
        .trello-header{
            z-index: 10;
            padding: 10px 16px;
            background-color: #fafafa;
            border-bottom: 1px solid #ebedf2;
            .pipline-name{
                cursor: -webkit-grab;
                cursor: -moz-grab;
                cursor: grab;
                font-size: 1rem;
            }
            .card-count{
                font-size: 14px;
                font-weight: 500;
            }
            .trello-progress{
                height: 6px;
                &.MuiLinearProgress-colorPrimary{
                    background-color:rgba(116,103,239,0.4);
                    .MuiLinearProgress-barColorPrimary{
                        background-color: var(--primary);
                    }                   
                }
                &.secondary{
                    background-color:rgba(255,158,67,0.4);
                    .MuiLinearProgress-barColorPrimary{
                        background-color: var(--secondary);
                    }
                }
                &.yellow{
                    background-color:rgba(242,191,88,0.4);
                    .MuiLinearProgress-barColorPrimary{
                        background-color: var(--yellow);
                    }
                }
                &.green{
                    background-color:rgba(8,173,108,0.4);
                    .MuiLinearProgress-barColorPrimary{
                        background-color: var(--green);
                    }
                }
                &.red{
                    background-color:rgba(255,0,0,0.4);
                    .MuiLinearProgress-barColorPrimary{
                        background-color: rgba(255,0,0,1);
                    }
                }
                &.blue{
                    background-color:rgba(0,0,255,0.4);
                    .MuiLinearProgress-barColorPrimary{
                        background-color:rgba(0,0,255,1);
                    }
                }
                &.black{
                    background-color:rgba(0,0,0,0.4);
                    .MuiLinearProgress-barColorPrimary{
                        background-color:rgba(0,0,0,1);
                    }
                }
                &.orange{
                    background-color:rgba(255,165,0,0.4);
                    .MuiLinearProgress-barColorPrimary{
                        background-color:rgba(255,165,0,1);
                    }
                }
                &.purple{
                    background-color:rgba(128,0,128,0.4);
                    .MuiLinearProgress-barColorPrimary{
                        background-color:rgba(128,0,128,1);
                    }
                }
                &.gray{
                    background-color:rgba(128,128,128,0.4);
                    .MuiLinearProgress-barColorPrimary{
                        background-color:rgba(128,128,128,1);
                    }
                }
            }
            + div{
                overflow: auto;
            }
        }
        .react-trello-lane{
            border: 1px solid #ebedf2;
            background-color: #fff;
            border-radius: 0;
            padding: 0;
            height: 100%;
            max-height:calc(100% - 3%);
            margin:5px 15px 5px 0;
            .smooth-dnd-draggable-wrapper{
                border-radius: 2px;
                margin-bottom: 10px;
                overflow: inherit;
                .react-trello-card{
                    border-bottom:0;                    
                    border: 1px solid#DCE3E6;
                    box-shadow: 0 1px 1.5px 0 rgba(0,0,0,0.12);
                    margin-bottom: 0;
                    &:hover{
                        background-color: #fafafa;
                    }
                    header{
                        border-bottom: 0;
                        margin-bottom: 0;
                        padding-bottom: 0;
                        h4{
                            font-size: 16px;
                            font-weight: 500;
                            margin-bottom: 3px;
                        }
                        .gDooAJ{
                            padding-right: 0;
                        }
                        .circle-bar{
                            // border: 2px solid var(--secondary);
                            width: 8px;
                            height: 8px;
                            margin-left:60px;
                            display: inline-block;
                            border-radius: 50%;
                            &.br-red{
                                border-color: var(--error);
                            }
                            &.br-green{
                                border-color: var(--green);
                            }
                        }
                        .fkOeUf{
                            font-size: 14px;
                            font-weight: 700;
                        }
                    }
                }
                .card-description{
                    white-space: nowrap;
                    .card-value{
                        font-size: 13px;
                        font-weight: 500;
                        color: var(--text-muted);
                    }
                    p{
                        margin: 0;
                    }
                }
            }
        }
        .filled_star{
            color:#F2BF58;
            font-size: 23px;
            position: absolute;
            margin-top: -3px;
        }
    }
}

.crm-opportunities-dnd{
    height: 80%;
    position: relative;
    margin-top: 10px;
    padding: 0 16px;    
    .title-with-label{
        .MuiSwitch-colorPrimary{
            &.Mui-checked{
                color: #08ad6c;
            }
        }
        .MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track {
            background-color:#08ad6c;
        }
        .MuiSwitch-colorPrimary.Mui-checked:hover {
            background-color:rgba(8,173,108, 0.08);
        }
        .MuiFormControlLabel-label{
            font-size: 14px;
        }
        .MuiInput-formControl{
            height: 40px;
            color: var( --text-muted);
        }
    }
    .react-trello-board{
        height: 100%;
        background-color: #fff;
        padding: 0;
        //overflow: auto;
        > div, .smooth-dnd-container{
            height: 100%;
        }
        .fixed-bottom{
            position: absolute;
            left: 0;
            right: 0;
            bottom: 10px;
            margin-bottom: 0;
            border: 0;
            border: 1px solid #DCE3E6;  
            cursor: text; 
            background-color: #fafafa;
            &:hover{
                background-color: #fafafa;
            }
            header{  
                margin-bottom: 0;
                border-bottom: 0;
                padding: 0;     
                display: block;   
                cursor: text; 
                .gDooAJ{
                    display: none;
                }                
                span{                    
                    cursor: text;
                }
            }
        }
        .trello-header{
            z-index: 10;
            padding: 10px 16px;
            background-color: #fafafa;
            border-bottom: 1px solid #ebedf2;
            .pipline-name{
                cursor: -webkit-grab;
                cursor: -moz-grab;
                cursor: grab;
                font-size: 1rem;
            }
            .card-count{
                font-size: 14px;
                font-weight: 500;
            }
            .trello-progress{
                height: 6px;
                &.MuiLinearProgress-colorPrimary{
                    background-color:rgba(116,103,239,0.4);
                    .MuiLinearProgress-barColorPrimary{
                        background-color: var(--primary);
                    }                   
                }
                &.secondary{
                    background-color:rgba(255,158,67,0.4);
                    .MuiLinearProgress-barColorPrimary{
                        background-color: var(--secondary);
                    }
                }
                &.yellow{
                    background-color:rgba(242,191,88,0.4);
                    .MuiLinearProgress-barColorPrimary{
                        background-color: var(--yellow);
                    }
                }
                &.green{
                    background-color:rgba(8,173,108,0.4);
                    .MuiLinearProgress-barColorPrimary{
                        background-color: var(--green);
                    }
                }
                &.red{
                    background-color:rgba(255,0,0,0.4);
                    .MuiLinearProgress-barColorPrimary{
                        background-color: rgba(255,0,0,1);
                    }
                }
                &.blue{
                    background-color:rgba(0,0,255,0.4);
                    .MuiLinearProgress-barColorPrimary{
                        background-color:rgba(0,0,255,1);
                    }
                }
                &.black{
                    background-color:rgba(0,0,0,0.4);
                    .MuiLinearProgress-barColorPrimary{
                        background-color:rgba(0,0,0,1);
                    }
                }
                &.orange{
                    background-color:rgba(255,165,0,0.4);
                    .MuiLinearProgress-barColorPrimary{
                        background-color:rgba(255,165,0,1);
                    }
                }
                &.purple{
                    background-color:rgba(128,0,128,0.4);
                    .MuiLinearProgress-barColorPrimary{
                        background-color:rgba(128,0,128,1);
                    }
                }
                &.gray{
                    background-color:rgba(128,128,128,0.4);
                    .MuiLinearProgress-barColorPrimary{
                        background-color:rgba(128,128,128,1);
                    }
                }
            }
        }
        .react-trello-lane{
            border: 1px solid #ebedf2;
            background-color: #fff;
            border-radius: 0;
            padding: 0;
            height: 100%;
            max-height:calc(100% - 3%);
            margin:5px 15px 5px 0;
            .smooth-dnd-container {
                &.vertical{
                    > span{
                        border-radius: 2px;
                        margin-bottom: 10px;
                        overflow: inherit;
                        display: block;  
                    }
                }
                .smooth-dnd-draggable-wrapper{
                    border-radius: 2px;
                    margin-bottom: 10px;
                    overflow: inherit;
                }
                .react-trello-card{
                    border-bottom:0;                    
                    border: 1px solid#DCE3E6;
                    box-shadow: 0 1px 1.5px 0 rgba(0,0,0,0.12);
                    margin-bottom: 0;
                    &:hover{
                        background-color: #fafafa;
                    }
                    header{
                        border-bottom: 0;
                        margin-bottom: 0;
                        padding-bottom: 0;
                        h4{
                            font-size: 16px;
                            font-weight: 500;
                            margin-bottom: 3px;
                        }
                        .gDooAJ{
                            padding-right: 0;
                        }
                        .circle-bar{
                            border: 2px solid var(--secondary);
                            width: 10px;
                            height: 10px;
                            display: inline-block;
                            border-radius: 50%;
                            &.br-red{
                                border-color: var(--error);
                            }
                            &.br-green{
                                border-color: var(--green);
                            }
                        }
                        .fkOeUf{
                            font-size: 14px;
                            font-weight: 700;
                        }
                    }
                }
                .card-description{
                    white-space: nowrap;
                    .card-value{
                        font-size: 13px;
                        font-weight: 500;
                        color: var(--text-muted);
                    }
                    p{
                        margin: 0;
                    }
                }
            }
        }
        .filled_star{
            color:#F2BF58;
            font-size: 23px;
            position: absolute;
            margin-top: -3px;
        }
    }
}

.task_popup{
    cursor: default;
    background-color: #fff;
    position: absolute;
    left: 100%;
    margin-left: -25px;
    top: 29px;
    width: 300px;
    z-index: 6;
    padding-top: 20px;
    border: 1px solid #DCE3E6;
    border-radius: 2px;
    margin-top: 10px;
    box-shadow: 0 1px 2px #DCE3E6;
    text-rendering: optimizeLegibility;
    .nub {
        border: inherit;
        box-shadow: inherit;
        border-radius: 2px;
        background-color: #fff;
        margin-left: -1px;
        padding: 8px 15px 6px;
        left: 0;
        top: -28px;
        height: 30px;
        position: absolute;
        text-align: center;
        font-size: 14px;
        cursor: pointer;
        .material-icons{
            font-size: var(--font-icon);
            color: #B57B08;
            cursor: pointer;
        }
        .border_cover {
            position: absolute;
            margin-top: -1px;
            top: 100%;
            left: 0;
            width: 105%;
            height: 10px;
            background-color: inherit;
        }
    }
    .tasks_title{
        padding:0 15px;
        font-size: 13px;
        font-weight: 500;
        color: #696E6F;
        position: relative;
        .add_task{
            cursor: pointer;
            position: absolute;
            right: 15px;
            top: 0;
            font-size: var(--font-icon);
        }
    }
    .empty_text {
        text-align: center;
        padding: 0 15px;
        margin: 20px 0;
        font-size: 18px;
        font-weight: 500;
        color: #DCE3E6;
        .task_button{
            margin-top: 20px;  
        }
    }
}

.bottom-left{
    bottom:109px !important;
}

.opportunities-dnd-project{
    height: calc(100vh - 125px);
    position: relative;
    margin-top: 10px;
    padding: 0 16px;    
    .title-with-label{
        .MuiSwitch-colorPrimary{
            &.Mui-checked{
                color: #08ad6c;
            }
        }
        .MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track {
            background-color:#08ad6c;
        }
        .MuiSwitch-colorPrimary.Mui-checked:hover {
            background-color:rgba(8,173,108, 0.08);
        }
        .MuiFormControlLabel-label{
            font-size: 14px;
        }
        .MuiInput-formControl{
            height: 40px;
            color: var( --text-muted);
        }
    }
    .react-trello-board{
        height: 93%;
        background-color: #fff;
        padding: 0;
        overflow: auto;
        > div, .smooth-dnd-container{
            height: 100%;
        }
        .fixed-bottom{
            position: absolute;
            left: 0;
            right: 0;
            bottom: 10px;
            margin-bottom: 0;
            border: 0;
            border: 1px solid #DCE3E6;  
            cursor: text; 
            background-color: #fafafa;
            &:hover{
                background-color: #fafafa;
            }
            header{  
                margin-bottom: 0;
                border-bottom: 0;
                padding: 0;     
                display: block;   
                cursor: text; 
                .gDooAJ{
                    display: none;
                }                
                span{                    
                    cursor: text;
                }
            }
        }
        .trello-header{
            z-index: 10;
            padding: 10px 16px;
            background-color: #fafafa;
            border-bottom: 1px solid #ebedf2;
            .pipline-name{
                cursor: -webkit-grab;
                cursor: -moz-grab;
                cursor: grab;
                font-size: 1rem;
            }
            .card-count{
                font-size: 14px;
                font-weight: 500;
            }
            .trello-progress{
                height: 6px;
                &.MuiLinearProgress-colorPrimary{
                    background-color:rgba(116,103,239,0.4);
                    .MuiLinearProgress-barColorPrimary{
                        background-color: var(--primary);
                    }                   
                }
                &.secondary{
                    background-color:rgba(255,158,67,0.4);
                    .MuiLinearProgress-barColorPrimary{
                        background-color: var(--secondary);
                    }
                }
                &.yellow{
                    background-color:rgba(242,191,88,0.4);
                    .MuiLinearProgress-barColorPrimary{
                        background-color: var(--yellow);
                    }
                }
                &.green{
                    background-color:rgba(8,173,108,0.4);
                    .MuiLinearProgress-barColorPrimary{
                        background-color: var(--green);
                    }
                }
                &.red{
                    background-color:rgba(255,0,0,0.4);
                    .MuiLinearProgress-barColorPrimary{
                        background-color: rgba(255,0,0,1);
                    }
                }
                &.blue{
                    background-color:rgba(0,0,255,0.4);
                    .MuiLinearProgress-barColorPrimary{
                        background-color:rgba(0,0,255,1);
                    }
                }
                &.black{
                    background-color:rgba(0,0,0,0.4);
                    .MuiLinearProgress-barColorPrimary{
                        background-color:rgba(0,0,0,1);
                    }
                }
                &.orange{
                    background-color:rgba(255,165,0,0.4);
                    .MuiLinearProgress-barColorPrimary{
                        background-color:rgba(255,165,0,1);
                    }
                }
                &.purple{
                    background-color:rgba(128,0,128,0.4);
                    .MuiLinearProgress-barColorPrimary{
                        background-color:rgba(128,0,128,1);
                    }
                }
                &.gray{
                    background-color:rgba(128,128,128,0.4);
                    .MuiLinearProgress-barColorPrimary{
                        background-color:rgba(128,128,128,1);
                    }
                }
            }
            + div{
                overflow: auto;
            }
        }
        .react-trello-lane{
            border: 1px solid #ebedf2;
            background-color: #fff;
            border-radius: 0;
            padding: 0;
            height: 100%;
            max-height:calc(100% - 3%);
            margin:5px 15px 5px 0;
            .smooth-dnd-draggable-wrapper{
                border-radius: 2px;
                margin-bottom: 10px;
                overflow: inherit;
                .react-trello-card{
                    border-bottom:0;                    
                    border: 1px solid#DCE3E6;
                    box-shadow: 0 1px 1.5px 0 rgba(0,0,0,0.12);
                    margin-bottom: 0;
                    &:hover{
                        background-color: #fafafa;
                    }
                    header{
                        border-bottom: 0;
                        margin-bottom: 0;
                        padding-bottom: 0;
                        h4{
                            font-size: 16px;
                            font-weight: 500;
                            margin-bottom: 3px;
                        }
                        .gDooAJ{
                            padding-right: 0;
                        }
                        .circle-bar{
                            border: 2px solid var(--secondary);
                            width: 12px;
                            height: 12px;
                            margin-left:60px;
                            display: inline-block;
                            border-radius: 50%;
                            &.br-red{
                                border-color: var(--error);
                            }
                            &.br-green{
                                border-color: var(--green);
                            }
                        }
                        .fkOeUf{
                            font-size: 14px;
                            font-weight: 700;
                        }
                    }
                }
                .card-description{
                    white-space: nowrap;
                    .card-value{
                        font-size: 13px;
                        font-weight: 500;
                        color: var(--text-muted);
                    }
                    p{
                        margin: 0;
                    }
                }
            }
        }
        .filled_star{
            color:#F2BF58;
            font-size: 23px;
            position: absolute;
            margin-top: -3px;
        }
    }
}
