.signup {
  background: #d9d4d4;
  

  .signup-card {
    max-width: 820px;
    border-radius: 0px !important;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19) !important;
    img {
      width: 200px;
    }
    .button_hover:hover {
      background-color: #0081D8 !important;
  }
  .MuiPaper-rounded{
    border-radius: 0px !important;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19) !important;
  }
  }
}
